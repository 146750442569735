import React from 'react';
import {Link} from "react-router-dom";


let images = [
    {
        image: '/assets/photo-gallery/DSC_5529.jpg',
        alt: 'Sign on rock'
    },
    {
        image: '/assets/photo-gallery/DSC_5530 cr.jpg',
        alt: 'Sign on rock close up'
    },
    {
        image: '/assets/photo-gallery/DSC_5531 cr.jpg',
        alt: 'map of trail'
    },
    {
        image: '/assets/photo-gallery/DSC_5533 cr.jpg',
        alt: 'saying'
    },
    {
        image: '/assets/photo-gallery/DSC_5534.jpg',
        alt: 'mile marker 1'
    },
    {
        image: '/assets/photo-gallery/DSC_5535 cr.jpg',
        alt: 'mile marker 2'
    },
    {
        image: '/assets/photo-gallery/DSC_5536.jpg',
        alt: 'mile marker 3'
    },
    {
        image: '/assets/photo-gallery/DSC_5537 cr.jpg',
        alt: 'mile marker 4'
    },
    {
        image: '/assets/photo-gallery/DSC_5538 cr.jpg',
        alt: 'mile marker 5'
    },
    {
        image: '/assets/photo-gallery/DSC_5539 cr.jpg',
        alt: 'mile marker 6'
    },
    {
        image: '/assets/photo-gallery/DSC_5540  cr.jpg',
        alt: 'Rock cliffside'
    },
    {
        image: '/assets/photo-gallery/DSC_5542.jpg',
        alt: 'mile marker 7'
    },
    {
        image: '/assets/photo-gallery/DSC_5543 cr.jpg',
        alt: 'Rock cliffside'
    },
    {
        image: '/assets/photo-gallery/DSC_5548.jpg',
        alt: 'mile marker 8'
    },
    {
        image: '/assets/photo-gallery/DSC_5549.jpg',
        alt: 'mile marker 9'
    },
    {
        image: '/assets/photo-gallery/DSC_5556.jpg',
        alt: 'mile marker 10'
    },
    {
        image: '/assets/photo-gallery/DSC_5557.jpg',
        alt: 'mile marker 11'
    },
    {
        image: '/assets/photo-gallery/DSC_5561.jpg',
        alt: 'mile marker 12'
    },
    {
        image: '/assets/photo-gallery/DSC_5562.jpg',
        alt: 'mile marker 13'
    },
    {
        image: '/assets/photo-gallery/DSC_5564.jpg',
        alt: 'red flower'
    },
    {
        image: '/assets/photo-gallery/DSC_5565.jpg',
        alt: 'mile marker 14'
    },
    {
        image: '/assets/photo-gallery/DSC_5567.jpg',
        alt: 'side by side'
    },
    {
        image: '/assets/photo-gallery/DSC_5569 mod.jpg',
        alt: 'Blonde woman'
    },
    {
        image: '/assets/photo-gallery/DSC_5570 mod.jpg',
        alt: 'Man with glasses'
    },
    {
        image: '/assets/photo-gallery/DSC_5572 mod.jpg',
        alt: 'Woman with glasses'
    },
    {
        image: '/assets/photo-gallery/DSC_5573.jpg',
        alt: 'Man in blue shirt'
    },
    {
        image: '/assets/photo-gallery/DSC_5575 cr.jpg',
        alt: 'man with spikey hair'
    },
    {
        image: '/assets/photo-gallery/DSC_5576 mod.jpg',
        alt: 'Brunette woman'
    },
    {
        image: '/assets/photo-gallery/DSC_5578 mod.jpg',
        alt: 'Man with dark hair'
    },
    {
        image: '/assets/photo-gallery/DSC_5579 mod.jpg',
        alt: 'Man with red shirt'
    },
    {
        image: '/assets/photo-gallery/DSC_5580 mod.jpg',
        alt: 'Man in polo'
    },
    {
        image: '/assets/photo-gallery/DSC_5587 mod.jpg',
        alt: 'Guitar player in front of a flag'
    },
    {
        image: '/assets/photo-gallery/DSC_5588.jpg',
        alt: 'Trailer'
    },
    {
        image: '/assets/photo-gallery/DSC_5589.jpg',
        alt: 'Officers with flags'
    },
    {
        image: '/assets/photo-gallery/DSC_5598 mod.jpg',
        alt: 'Award ceremony '
    },
    {
        image: '/assets/photo-gallery/DSC_5602 mod.jpg',
        alt: 'Award received'
    },
    {
        image: '/assets/photo-gallery/DSC_5606 mod.jpg',
        alt: 'UPOA Award received'
    },
    {
        image: '/assets/photo-gallery/DSC_5609 mod.jpg',
        alt: 'UPOA Award received'
    },
    {
        image: '/assets/photo-gallery/DSC_5613 mod.jpg',
        alt: 'Bagpipe player'
    },
    {
        image: '/assets/photo-gallery/DSC_5614.jpg',
        alt: 'Gun ceremony'
    },
    {
        image: '/assets/photo-gallery/DSC_5625 mod.jpg',
        alt: 'Poster board '
    },
    {
        image: '/assets/photo-gallery/DSC_5627 mod.jpg',
        alt: 'Poster board '
    },
    {
        image: '/assets/photo-gallery/DSC_5628 cr2.jpg',
        alt: 'Letter'
    },
    {
        image: '/assets/photo-gallery/DSC_5629.jpg',
        alt: 'Poster board '
    },
    {
        image: '/assets/photo-gallery/DSC_5630 cr.jpg',
        alt: 'Poster board '
    },
    {
        image: '/assets/photo-gallery/DSC_5631 cr.jpg',
        alt: 'Officer '
    },
    {
        image: '/assets/photo-gallery/DSC_5635.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5636 mod.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5640 cr.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5643.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5646 cr.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5648 cr.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5651 cr.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5654 cr.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5656 cr.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5657.jpg',
        alt: 'Officer Display '
    },
    {
        image: '/assets/photo-gallery/DSC_5659 mod.jpg',
        alt: 'Outlook '
    },
]


export default function PhotoGallery() {
    
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Photo Gallery</strong> </h1>
                <br></br>                    
            </section>
            {/* Need to create a form / Page to submit nominations  */}
            <div class="is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">
                <Link to="/Photo-Submission">
                    <a class="center m-1" href="/Photo-Submission" >
                        <button class="light-bg center p-4 "><strong class="is-size-5">Submit a photo</strong></button>
                    </a>
                    
                </Link>
                <a class="center m-1" href="https://drive.google.com/drive/folders/1JCHVLsl3hSFy6j7-tQAhzgytCAA1wcZi?usp=share_link" >
                    <button class="light-bg center p-4 "><strong class="is-size-5">Family Ride Photo's</strong></button>
                </a>
                <a class="center m-1" href="https://drive.google.com/drive/folders/1-2XAIML-VPqoMKmZQFqdcANPs7Gbl0v9?usp=share_link" >
                    <button class="light-bg center p-4 "><strong class="is-size-5">Additional Ride Photos</strong></button>
                </a>
            </div>

            <hr />
            <div class="m-4 is-size-5">
                
                <div class="is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">
                    <div>
                        {images.map(data => (
                            <>
                                <img class= "m-4 photo-gallery" width={400} src={data.image} alt={data.alt} />
                            </>
                        ))}
                    </div>
                    
                </div>

            </div>
            
        </>
    )
}