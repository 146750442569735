import React from 'react';
import {Link} from "react-router-dom";

export default function FallenOfficers() {




    // const data2023 = [
        
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    //     {
    //         agency: '',
    //         name: '',
    //         endOfWatch: '',
    //         image: '',
            
    //     },
    // ]
    const data2022 = [
        
        {
            name: 'Dennis Vincent',
            agency: 'Brigham City Police Department',
            endOfWatch: 'October 26, 2018',
            image: ''
        },
        {
            name: 'William Levi Black',
            agency: 'Emery County Sheriff Deputy ',
            endOfWatch: ' August 22, 1936',
            image: '/assets/fallen-officers-photos/Black.jpg',
            
        },
        {
            name: 'Nathan Lyday',
            agency: 'Ogden Police Department Officer  ',
            endOfWatch: 'May 28, 2020',
            image: ''
        },
       
        {
            name: 'Joseph Shinners',
            agency: 'Provo Police Department Officer',
            endOfWatch: 'January 5, 2019',
            image: '',
            
        },
        {
            name: 'Oscar Fullmer',
            agency: 'Salt Lake County Sheriff Deputy',
            endOfWatch: 'August 26, 1928',
            image: '/assets/fallen-officers-photos/Fullmer.jpg',
            
        },
        {
            name: 'Nolan W. Huntsman',
            agency: 'Salt Lake Police Department Officer',
            endOfWatch: 'February 15, 1924',
            image: '/assets/fallen-officers-photos/Huntsman.jpg',
            
        },
        {
            name: ' Brigham H. Honey',
            agency: 'Salt Lake Police Department Officer ',
            endOfWatch: 'February 16, 1924',
            image: '/assets/fallen-officers-photos/Honey.jpg',
            
        },
        {
            name: 'David Romrell',
            agency: 'Salt Lake Police Department Officer ',
            endOfWatch: 'November 24, 2018',
            image: '',
            
        },
        {
            name: 'Joseph C. Akin',
            agency: 'United States Marshals Service',
            endOfWatch: 'Februray 21, 1915',
            image: '',
            
        },
        {
            name: 'William R. Storey',
            agency: 'United States Marshals Service',
            endOfWatch: 'May 2, 1870',
            image: '/assets/fallen-officers-photos/Story.jpg',
            
        },
        {
            name: 'Robert B Hutchings',
            agency: 'Utah Department Public Safety Officer',
            endOfWatch: 'July 20, 1976',
            image: '/assets/fallen-officers-photos/Hutchings.jpg',
            
        },
        {
            name: 'George D. Rees',
            agency: 'Utah Highway Patrol Trooper',
            endOfWatch: 'July 2, 1960',
            image: '/assets/fallen-officers-photos/Rees,G.jpg',
            
        },
        {
            name: 'Armond A. "Monty" Luke',
            agency: 'Utah Highway Patrol Trooper ',
            endOfWatch: 'December 3, 1959',
            image: '/assets/fallen-officers-photos/Luke.jpg',
            
        },
        {
            name: 'Adolph F. Bush',
            agency: 'Ute Tribal Police Department Officer ',
            endOfWatch: 'August 19, 1967',
            image: '/assets/fallen-officers-photos/Bush.jpg',
            
        },

    ]
    const data2019 = [
        
        {
            agency: 'Frisco Police Department Officer ',
            name: 'Daniel Mahoney',
            endOfWatch: 'Augustust 5, 1883 *',
            image: '',
            
        },
        {
            agency: 'Millard County Sheriff Deputy',
            name: 'Floyd L. Rose',
            endOfWatch: 'October 2, 1922',
            image: '/assets/fallen-officers-photos/Rose.jpg',
            
        },
        {
            agency: 'Mt. Pleasant Police Department Officer',
            name: 'Alonzo "Lon" T. Larsen ',
            endOfWatch: 'October 15, 1945',
            image: '/assets/fallen-officers-photos/Larsen, L.jpg',
            
        },
        {
            agency: 'National Forest Service Officer ',
            name: 'Rudolph E. Mellenthin',
            endOfWatch: 'October 15, 1945',
            image: '/assets/fallen-officers-photos/Mellenthin.jpg',
            
        },
        // {
        //     agency: 'National Forest Service Officer ',
        //     name: 'Rudolph E. Mellenthin',
        //     endOfWatch: 'August 23, 1918',
        //     image: '',
            
        // },
        {
            agency: 'Salt Lake County Sheriff Deputy',
            name: 'MacKay C. Jewkes',
            endOfWatch: 'June 28, 1959',
            image: '/assets/fallen-officers-photos/Jewkes.jpg',
            
        },
        {
            agency: 'Salt Lake County Sheriff Deputy ',
            name: 'Otto Witbeck',
            endOfWatch: 'November 21, 1913',
            image: '/assets/fallen-officers-photos/Witbeck.jpg',
            
        },
        {
            agency: 'Salt Lake Police Department Sergeant',
            name: 'Thomas W. Stroud',
            endOfWatch: 'January 5, 1951',
            image: '/assets/fallen-officers-photos/Stroud1.jpg',
            
        },
        {
            agency: 'Salt Lake Police Department Sergeant. ',
            name: 'Alonzo Mispah Wilson',
            endOfWatch: 'April 12, 1894',
            image: '/assets/fallen-officers-photos/Wilson2.jpg',
            
        },
        {
            agency: 'Springville Police Department Officer',
            name: 'Levi W. Davis',
            endOfWatch: 'May 7, 1860 *',
            image: '',
            
        },
        {
            agency: 'Uinta County Sheriff (WY) Deputy ',
            name: 'Edward N. Dawes',
            endOfWatch: 'July 30, 1895 *',
            image: '/assets/fallen-officers-photos/Dawes03.jpg',
            
        },
        {
            agency: 'Utah Department of Corrections Lieutenant ',
            name: 'Fred F. House',
            endOfWatch: 'January 28, 1988',
            image: '/assets/fallen-officers-photos/House.jpg',
            
        },
        {
            agency: 'Utah Department of Corrections Officer ',
            name: 'Don Wagstaff',
            endOfWatch: 'December 12, 1970',
            image: '/assets/fallen-officers-photos/Wagstaff.jpg',
            
        },
        {
            agency: 'Utah Highway Patrol Lieutenant',
            name: 'Thomas S. Rettberg',
            endOfWatch: ' February 11, 2000',
            image: '/assets/fallen-officers-photos/Rettberg02.jpg',
            
        },
    ]

    const data2018 = [
        
        {
            agency: 'Colorado State Trooper ',
            name: 'Wesley Rosette',
            endOfWatch: 'January 31, 1951',
            image: '/assets/fallen-officers-photos/Rosette.jpg',
            
        },
        {
            agency: 'Deputy State Game Warden ',
            name: 'Ernest G. Berri',
            endOfWatch: 'September 26, 1914',
            image: '/assets/fallen-officers-photos/Berri.jpg',
            
        },
        {
            agency: 'Garfield County Deputy ',
            name: 'David C. Jones ',
            endOfWatch: 'January 26, 2003',
            image: '/assets/fallen-officers-photos/Jones.jpg',
            
        },
        {
            agency: 'Grantsville Police Department Officer ',
            name: 'Festus Sprague',
            endOfWatch: 'May 3, 1870',
            image: '/assets/fallen-officers-photos/Sprague.jpg',
            
        },
        {
            agency: 'Ogden Police Department Officer ',
            name: 'Joseph H. Quigley',
            endOfWatch: 'July 12, 1935',
            image: '/assets/fallen-officers-photos/Quigley.jpg',
            
        },
        {
            agency: 'Salt Lake County Sheriff Deputy/Unified Police ',
            name: 'Brian Holdaway',
            endOfWatch: 'February 2,2017',
            image: '',
            
        },
        {
            agency: 'Salt Lake Police Department Officer',
            name: 'Rollin Tanner',
            endOfWatch: 'Sepember 10, 1927',
            image: '',
            
        },
        {
            agency: 'Salt Lake Police Department Officer ',
            name: 'Harold A. Peterson Sr.',
            endOfWatch: 'October 27, 1954',
            image: '/assets/fallen-officers-photos/Peterson.jpg',
            
        },
        {
            agency: 'San Juan County Sheriff Deputy ',
            name: 'Carlos Hall',
            endOfWatch: 'August 20, 1965',
            image: '/assets/fallen-officers-photos/Hall.jpg',
            
        },
        {
            agency: 'Sanpete County Sheriff Deputy ',
            name: 'James C. Burns',
            endOfWatch: ' September 26, 1894',
            image: '/assets/fallen-officers-photos/Burns, j.jpg',
            
        },
        {
            agency: 'Utah Highway Patrol Trooper ',
            name: 'Erick Dale Ellsworth',
            endOfWatch: 'November 22, 2016',
            image: '',
            
        },

    ]
    const data2017 = [
        
        {
            agency: 'Denver & Rio Grande Western RR Agent',
            name: 'Steven W. Harton',
            endOfWatch: 'September 15, 1990',
            image: '/assets/fallen-officers-photos/Harton.jpg',
            
        },
        {
            agency: 'Grand County Sheriff Deputy',
            name: 'Samuel Jenkins',
            endOfWatch: ' May 26, 1900 *',
            image: '',
            
        },
        {
            agency: 'Lehi Police Officer',
            name: 'Joseph Dan Adams ',
            endOfWatch: 'August 4, 2001',
            image: '/assets/fallen-officers-photos/Adams.jpg',
            
        },
        {
            agency: 'Murray Police Department Officer ',
            name: 'Jackson D. Elmer',
            endOfWatch: 'November 14, 1987',
            image: '/assets/fallen-officers-photos/Elmer02.jpg',
            
        },
        {
            agency: 'Ogden Police Department Officer ',
            name: 'Clarence M Bean',
            endOfWatch: 'May 1, 1945',
            image: '/assets/fallen-officers-photos/Bean.jpg',
            
        },
        {
            agency: 'Park City Police Department Officer',
            name: 'Rodney W. Schreurs',
            endOfWatch: 'July 4, 1984',
            image: '/assets/fallen-officers-photos/Schreurs.jpg',
            
        },
        {
            agency: 'Salt Lake County Sheriff Deputy ',
            name: 'Thomas Manderich',
            endOfWatch: 'November 29, 1913',
            image: '/assets/fallen-officers-photos/Manderich.jpg',
            
        },
        {
            agency: 'Salt Lake County Sheriff Deputy ',
            name: 'J. Douglas Hulsey',
            endOfWatch: 'November 29, 1913',
            image: '/assets/fallen-officers-photos/Hulsey.jpg',
            
        },
        {
            agency: 'Salt Lake County Sheriff Deputy/Unified Police ',
            name: 'Douglas Barney',
            endOfWatch: 'January 17, 2016',
            image: '',
            
        },
        {
            agency: 'Salt Lake Police Department Officer',
            name: "Greenville 'Green' B. Hamby",
            endOfWatch: 'February 8, 1921',
            image: '/assets/fallen-officers-photos/Hamby.jpg',
            
        },
        {
            agency: 'Salt Lake Police Department Officer ',
            name: 'Blaine L. Baxter',
            endOfWatch: 'September 4, 1935',
            image: '/assets/fallen-officers-photos/Baxter.jpg',
            
        },
        {
            agency: 'Springville City Marshal',
            name: 'Silas E. Clark',
            endOfWatch: 'November 18, 1897',
            image: '/assets/fallen-officers-photos/Clark,Silas.jpg',
            
        },
        {
            agency: 'Utah Highway Patrol Trooper ',
            name: 'William J. Antoniewicz',
            endOfWatch: 'December 8, 1974',
            image: '/assets/fallen-officers-photos/Antoniewicz.jpg',
            
        },
       
    ]
    const data2016 = [
        
        {
            agency: 'Cedar City PD',
            name: 'Chief George Davis',
            endOfWatch: 'March 26, 1969',
            image: '',
            
        },
        {
            agency: 'Davis County Sheriff Deputy ',
            name: 'Donald Perry Jensen',
            endOfWatch: 'May 14, 1971',
            image: '/assets/fallen-officers-photos/Jensen, D.jpg',
            
        },
        {
            agency: 'Echo Police Department',
            name: 'Thomas Stagg',
            endOfWatch: 'July 30, 1895 *',
            image: '/assets/fallen-officers-photos/Stagg.jpg',
            
        },
        {
            agency: 'Emery County Sheriff Deputy ',
            name: 'Wade A. Hansen',
            endOfWatch: 'September 24, 1987',
            image: '/assets/fallen-officers-photos/Hansen1.jpg',
            
        },
        {
            agency: 'Navajo Division of Public Safety Officer',
            name: 'Andy Begay',
            endOfWatch: 'December 5, 1987',
            image: '/assets/fallen-officers-photos/Begay.jpg',
            
        },
        {
            agency: 'Navajo Division of Public Safety Officer ',
            name: 'Roy Lee Stanley',
            endOfWatch: 'December 5, 1987',
            image: '/assets/fallen-officers-photos/Stanley.jpg',
            
        },
        {
            agency: 'North Salt Lake Police Department',
            name: 'Charles Skinner',
            endOfWatch: 'November 8, 2008',
            image: '',
            
        },
        {
            agency: 'Roosevelt Police Department Chief ',
            name: 'Cecil Gurr',
            endOfWatch: 'July 6, 2001',
            image: '/assets/fallen-officers-photos/Gurr.jpg',
            
        },
        {
            agency: 'Salt Lake County Sheriff Deputy',
            name: 'Rodney Badger',
            endOfWatch: 'April 29, 1853',
            image: '/assets/fallen-officers-photos/Badger.jpg',
            
        },
        {
            agency: 'Salt Lake Police Department Officer',
            name: 'Ronald L. Heaps',
            endOfWatch: 'January 13, 1982',
            image: '/assets/fallen-officers-photos/Heaps.jpg',
            
        },
        {
            agency: 'Utah Department of Corrections  Warden ',
            name: 'Matthew B. Burgher',
            endOfWatch: 'March 16, 1876',
            image: '/assets/fallen-officers-photos/Burgher.jpg',
            
        },
        {
            agency: 'Utah Highway Patrol Trooper ',
            name: 'Dennis L. "Dee" Lund',
            endOfWatch: 'June 16, 1993',
            image: '/assets/fallen-officers-photos/Lund, D.jpg',
            
        },
        {
            agency: 'West Jordan Police Department Officer',
            name: 'Thomas M. Rees',
            endOfWatch: 'February 23, 1986',
            image: '/assets/fallen-officers-photos/Rees,M.jpg',
            
        },
       
    ]
    const data2015 = [
        
        {
            agency: 'Bingham Police Department Officer',
            name: 'J. C. Morrissey',
            endOfWatch: 'July 6, 1895 *',
            image: '',
            
        },
        {
            agency: 'Draper Police Department',
            name: 'Derrick Johnson',
            endOfWatch: 'September 1, 2013',
            image: '/assets/fallen-officers-photos/Derek (2).jpg',
            
        },
        {
            agency: 'Emery County Sheriff Deputy ',
            name: 'Jeremiah K. Johnson',
            endOfWatch: 'May 27, 2003',
            image: '/assets/fallen-officers-photos/Johnson.jpg',
            
        },
        {
            agency: 'Grand County Sheriff Deputy ',
            name: 'Richard D. Westwood',
            endOfWatch: 'September 5, 1929',
            image: '/assets/fallen-officers-photos/Westwood.jpg',
            
        },
        {
            agency: 'Logan Police Department Officer ',
            name: 'Willard R. Dahle',
            endOfWatch: 'May 4, 1929',
            image: '/assets/fallen-officers-photos/Dahle.jpg',
            
        },
        {
            agency: 'Ogden Police Department Officer',
            name: 'Marshall "Doc" N.White',
            endOfWatch: 'October 13, 1963',
            image: '/assets/fallen-officers-photos/White.jpg',
            
        },
        {
            agency: 'Salt Lake County Sheriff Deputy',
            name: 'Gordon Stuart',
            endOfWatch: 'April 15, 1922',
            image: '/assets/fallen-officers-photos/Stuart.jpg',
            
        },
        {
            agency: 'Salt Lake Police Department Officer',
            name: 'William Cooke ',
            endOfWatch: 'October 18, 1858 *',
            image: '',
            
        },
        {
            agency: 'Salt Lake Police Department Sergeant',
            name: 'James E. Faraone',
            endOfWatch: 'September 18, 2001',
            image: '/assets/fallen-officers-photos/Faraone.jpg',
            
        },
        {
            agency: "Utah County Sheriff's Department",
            name: 'Cory Wride',
            endOfWatch: 'February 18, 2014',
            image: '',
            
        },
        {
            agency: 'Utah Highway Patrol Sergeant ',
            name: 'Doyle R. Thorne',
            endOfWatch: 'July 30, 1994',
            image: '/assets/fallen-officers-photos/Thorne.jpg',
            
        },
        {
            agency: 'Weber County Sheriff Deputy ',
            name: 'Seymour Clark',
            endOfWatch: 'November 27, 1908  ',
            image: '/assets/fallen-officers-photos/Clark, Seymore.jpg',
            
        },
       
    ]
    const data2014 = [
        
        {
            agency: 'Cache County Sheriff Lieutenant',
            name: 'James R. Merrill',
            endOfWatch: 'January 5, 1974',
            image: '/assets/fallen-officers-photos/Merrill.jpg',
            
        },
        {
            agency: 'Carbon County Sheriff Deputy ',
            name: 'S. Marion Bliss',
            endOfWatch: 'April 23, 1945',
            image: '/assets/fallen-officers-photos/Bliss.jpg',
            
        },
        {
            agency: 'Duchesne County Sheriff Deputy ',
            name: 'Gerry Ivie',
            endOfWatch: 'July 2, 1987',
            image: '/assets/fallen-officers-photos/Ivie.jpg',
            
        },
        {
            agency: 'Iron County Deputy ',
            name: 'Edward (Ed) N. Dare',
            endOfWatch: 'September 24, 2002',
            image: '/assets/fallen-officers-photos/Dare.jpg',
            
        },
        {
            agency: 'Navajo Division of Public Safety Officer ',
            name: 'Esther Todecheene',
            endOfWatch: 'June 8, 1998',
            image: '/assets/fallen-officers-photos/Todecheene.jpg',
            
        },
        {
            agency: 'Richfield Marshal',
            name: 'Lee Franklin Isbell',
            endOfWatch: 'August 5, 1921',
            image: '/assets/fallen-officers-photos/Isbell.jpg',
            
        },
        {
            agency: 'Salt Lake County Sheriff Deputy',
            name: 'Melvin C. Colebrook',
            endOfWatch: 'March 10, 1973',
            image: '/assets/fallen-officers-photos/Colebrook.jpg',
            
        },
        {
            agency: 'Salt Lake Police Department Detective',
            name: 'Percy Lindsay Clark',
            endOfWatch: 'January 11, 1973',
            image: '/assets/fallen-officers-photos/Clark, P.jpg',
            
        },
        {
            agency: 'Salt Lake Police Department Officer ',
            name: 'Andrew H. Burt',
            endOfWatch: 'August 25, 1883',
            image: '/assets/fallen-officers-photos/Burt.jpg',
            
        },
        {
            agency: 'Utah Department of Corrections Officer ',
            name: 'Edwin Fisher',
            endOfWatch: 'June 1, 1955',
            image: '/assets/fallen-officers-photos/Fisher.jpg',
            
        },
        {
            agency: 'Utah Highway Patrol Trooper',
            name: 'Daniel W. Harris',
            endOfWatch: 'August 28, 1982',
            image: '/assets/fallen-officers-photos/Harris.jpg',
            
        },
        {
            agency: 'West Jordan Police Department Officer',
            name: 'Ronald Wood',
            endOfWatch: 'November 18, 2002',
            image: '/assets/fallen-officers-photos/Wood1.jpg',
            
        },
    
    ]
    const data2013 = [
        
        {
            agency: 'Bureau of Indian Affairs Officer',
            name: 'Joshua Yazzie',
            endOfWatch: 'June 7, 2010',
            image: '',
            
        },
        {
            agency: 'Kane County Sherrifs Deputy',
            name: 'Brian Harris',
            endOfWatch: 'August 26, 2010',
            image: '/assets/fallen-officers-photos/Harris.jpg',
            
        },
        {
            agency: 'Millard County Sheriff Deputy',
            name: 'Josie Greathouse Fox',
            endOfWatch: 'January 5, 2010',
            image: '',
            
        },
        {
            agency: 'Moab Police Department Officer ',
            name: 'Lloyd Larsen',
            endOfWatch: 'January 5, 1961',
            image: '/assets/fallen-officers-photos/Larsen, L.jpg',
            
        },
        {
            agency: 'Ogden Police Department Officer ',
            name: 'Jared Francom',
            endOfWatch: 'January 5, 2012',
            image: '',
            
        },
        {
            agency: 'Provo Police Department Officer',
            name: 'William Strong',
            endOfWatch: 'June 27, 1899',
            image: '/assets/fallen-officers-photos/Strong1.jpg',
            
        },
        {
            agency: 'Salt Lake Police Department Officer ',
            name: 'Owen T. Farley',
            endOfWatch: 'May 23, 1951',
            image: '/assets/fallen-officers-photos/Farley.jpg',
            
        },
        {
            agency: 'Sevier County Sheriff Deputy',
            name: 'Franco Aguilar',
            endOfWatch: 'April 29, 2010',
            image: '',
            
        },
        {
            agency: 'Uintah County Sheriff  Detective ',
            name: 'Kevin Orr ',
            endOfWatch: 'November 22, 2006',
            image: '/assets/fallen-officers-photos/Orr02.jpg',
            
        },
        {
            agency: 'Utah Department of Corrections Officer ',
            name: 'Stephen Anderson',
            endOfWatch: 'June 25, 2007',
            image: '/assets/fallen-officers-photos/Anderson, S.jpg',
            
        },
        {
            agency: 'Utah Divison Wildlife Resources Officer ',
            name: 'Charles Gilbert"Gil" Porter',
            endOfWatch: 'May 2, 1970',
            image: '/assets/fallen-officers-photos/Porter.jpg',
            
        },
        {
            agency: 'Utah Highway Patrol Trooper ',
            name: 'George "Ed" Vanwagenen',
            endOfWatch: 'May 1, 1931',
            image: '/assets/fallen-officers-photos/VanWagenen.jpg',
            
        },
        {
            agency: 'Utah Highway Patrol Trooper ',
            name: 'Aaron Beesley',
            endOfWatch: 'June 30, 2012',
            image: '',
            
        },
        {
            agency: 'Washington County Sheriff Deputy',
            name: 'John Cottam ',
            endOfWatch: 'June 2, 1936',
            image: '/assets/fallen-officers-photos/Cottam.jpg',
            
        },
       
    ]




    const halfyears ={
        
        year1:2019,
        year2:2018,
        year3:2017,
        year4:2016,
        year5:2015,
        year6:2014,
        year7:2013,
        // Will update data years for half sections with each year and move downone
        // year8:2013,

        half1: data2019,
        half2: data2018,
        half3: data2017,
        half4: data2016,
        half5: data2015,
        half6: data2014,
        half7: data2013,
        // Will update data years for half sections with each year and move downone
        // half8: data2013,
        
    }

    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Fallen Officers</strong> </h1>
                <br></br>                    
            </section>
            {/* Need to create a form / Page to submit nominations  */}
            <Link to="/Nominate-Officer">
                <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/Nominate-Officer" >
                    <button class="light-bg center p-4 "><strong class="is-size-5">Nominate a fallen officer</strong></button>
                </a>
            </Link>
            
            <hr />
            {/* Tiles with Images */}
            <h2 class="center is-size-3 light-bg txt-light"><strong>2022</strong></h2>
            <div class=" center is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4  is-flex-wrap-wrap ">
                
                {data2022.map(data => (
                    <>
                        
                        <div class="box" width="100px"  >
                            <div class="center">
                                <img class="officer-img" src={data.image} alt={data.name} width="100%"  />
                            </div>
                            
                            <br />
                            <h3 class="center is-size-4"><strong>{data.name}</strong></h3>
                            <p class="center is-size-5">Agency: {data.agency}</p>
                            <p class="center is-size-5">End Of Watch: {data.endOfWatch}</p>
                            {/* <p class="center is-size-6">Sponsored by: Persons name</p> */}
                        </div>
                    </>
                    
                ))}
                
            </div>


           



            {/* Splitting screen in half With double boxes */}
            <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-4 ">
                <div class="half">
                    <h2 class="center is-size-3 light-bg"><strong>{halfyears.year1}</strong></h2>
                    <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-1  is-flex-wrap-wrap">
                        
                        {halfyears.half1.map(data => (
                            <>
                                
                                <div class="box is-size-6">
                                    <div class="center">
                                        <img class="officer-img" src={data.image} alt={data.name} width="100%"  />
                                    </div>
                                    <br />
                                    <h3 class="center is-size-4"><strong>{data.name}</strong></h3>
                                    <p class="center  "><strong>Agency </strong>: {data.agency}</p>
                                    <p class="center"><strong>End Of Watch</strong>: {data.endOfWatch}</p>
                                </div>
                            </>
                            
                        ))}
                        
                    </div>

                </div>

                <div class="half">
                    <h2 class="center is-size-3 light-bg"><strong>{halfyears.year2}</strong></h2>
                    <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-1  is-flex-wrap-wrap">
                        
                        {halfyears.half2.map(data => (
                            <>
                                
                                <div class="box is-size-6 ">
                                    <div class="center">
                                        <img class="officer-img" src={data.image} alt={data.name} width="100%"  />
                                    </div>
                                    <h3 class="center is-size-4"><strong>{data.name}</strong></h3>
                                    <p class="center  "><strong>Agency </strong>: {data.agency}</p>
                                    <p class="center"><strong>End Of Watch</strong>: {data.endOfWatch}</p>
                                </div>
                            </>
                            
                        ))}
                        
                    </div>

                </div>
            </div>


            <br />
            <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-4 ">
                <div class="half">
                    <h2 class="center is-size-3 light-bg"><strong>{halfyears.year3}</strong></h2>
                    <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-1  is-flex-wrap-wrap">
                        
                        {halfyears.half3.map(data => (
                            <>
                                
                                <div class="box is-size-6">
                                    <div class="center">
                                        <img class="officer-img" src={data.image} alt={data.name} width="100%"  />
                                    </div>
                                    <br />
                                    <h3 class="center is-size-4"><strong>{data.name}</strong></h3>
                                    <p class="center  "><strong>Agency </strong>: {data.agency}</p>
                                    <p class="center"><strong>End Of Watch</strong>: {data.endOfWatch}</p>
                                </div>
                            </>
                            
                        ))}
                        
                    </div>

                </div>

                <div class="half">
                    <h2 class="center is-size-3 light-bg"><strong>{halfyears.year4}</strong></h2>
                    <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-1  is-flex-wrap-wrap">
                        
                        {halfyears.half4.map(data => (
                            <>
                                
                                <div class="box is-size-6 ">
                                    <div class="center">
                                        <img class="officer-img" src={data.image} alt={data.name} width="100%"  />
                                    </div>
                                    <h3 class="center is-size-4"><strong>{data.name}</strong></h3>
                                    <p class="center  "><strong>Agency </strong>: {data.agency}</p>
                                    <p class="center"><strong>End Of Watch</strong>: {data.endOfWatch}</p>
                                </div>
                            </>
                            
                        ))}
                        
                    </div>

                </div>
            </div>

            

            <br />
            <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-4 ">
                <div class="half">
                    <h2 class="center is-size-3 light-bg"><strong>{halfyears.year5}</strong></h2>
                    <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-1  is-flex-wrap-wrap">
                        
                        {halfyears.half5.map(data => (
                            <>
                                
                                <div class="box is-size-6">
                                    <div class="center">
                                        <img class="officer-img" src={data.image} alt={data.name} width="100%"  />
                                    </div>
                                    <br />
                                    <h3 class="center is-size-4"><strong>{data.name}</strong></h3>
                                    <p class="center  "><strong>Agency </strong>: {data.agency}</p>
                                    <p class="center"><strong>End Of Watch</strong>: {data.endOfWatch}</p>
                                </div>
                            </>
                            
                        ))}
                        
                    </div>

                </div>

                <div class="half">
                    <h2 class="center is-size-3 light-bg"><strong>{halfyears.year6}</strong></h2>
                    <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-1  is-flex-wrap-wrap">
                        
                        {halfyears.half6.map(data => (
                            <>
                                
                                <div class="box is-size-6 ">
                                    <div class="center">
                                        <img class="officer-img" src={data.image} alt={data.name} width="100%"  />
                                    </div>
                                    <h3 class="center is-size-4"><strong>{data.name}</strong></h3>
                                    <p class="center  "><strong>Agency </strong>: {data.agency}</p>
                                    <p class="center"><strong>End Of Watch</strong>: {data.endOfWatch}</p>
                                </div>
                            </>
                            
                        ))}
                        
                    </div>

                </div>
            </div>

            

            <br />
            <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-4 ">
                <div class="half">
                    <h2 class="center is-size-3 light-bg"><strong>{halfyears.year7}</strong></h2>
                    <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-1  is-flex-wrap-wrap">
                        
                        {halfyears.half7.map(data => (
                            <>
                                
                                <div class="box is-size-6">
                                    <div class="center">
                                        <img class="officer-img" src={data.image} alt={data.name} width="100%"  />
                                    </div>
                                    <br />
                                    <h3 class="center is-size-4"><strong>{data.name}</strong></h3>
                                    <p class="center  "><strong>Agency </strong>: {data.agency}</p>
                                    <p class="center"><strong>End Of Watch</strong>: {data.endOfWatch}</p>
                                </div>
                            </>
                            
                        ))}
                        
                    </div>

                </div>

                {/* <div class="half">
                    <h2 class="center is-size-3 light-bg"><strong>{halfyears.year8}</strong></h2>
                    <div class="is-flex-desktop  is-justify-content-space-between is-flex-direction-row m-1  is-flex-wrap-wrap">
                        
                        {halfyears.half8.map(data => (
                            <>
                                
                                <div class="box is-size-6 ">
                                    <div class="center">
                                        <img class="officer-img" src={data.image} alt={data.name} width="100%"  />
                                    </div>
                                    <h3 class="center is-size-4"><strong>{data.name}</strong></h3>
                                    <p class="center  "><strong>Agency </strong>: {data.agency}</p>
                                    <p class="center"><strong>End Of Watch</strong>: {data.endOfWatch}</p>
                                </div>
                            </>
                            
                        ))}
                        
                    </div>

                </div> */}
            </div>
            
        </>
    )
}