import React from 'react';
import {Link} from "react-router-dom";

var bgColors = {
    "Dark":"#161630" 
}


const NavBar = () => {
    const [isActive, setisActive] = React.useState(false)
    return(
        <>
{/* Start of mobile / tablet view nav Canyonredrock13 */}
        <div class="" >
            <div class="is-hidden-desktop m-4 pt-3 is-spaced" >
                <nav  >
                    <div 
                        // className='navbar is-hidden-desktop is-spaced' role='navigation' aria-label='main navigation' style={{ backgroundImage: `url(${'../../assets/images/Canyonredrock15.jpg'})`, 
                        // backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}
                        >

                        <div className='navbar-brand is-hidden-desktop '>
                            {/* <a href="/" class="$-img-max-height " >  <img class="logo" src={require('../../assets/images/small-logo.png').default}  width="100" alt="logo"></img></a> */}
                            
                            <div
                            
                            onClick={() => {
                                setisActive(!isActive)
                            }}
                            role='button'
                            className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
                            aria-label='menu'
                            aria-expanded='false'
                            data-target='navbar'
                            
                            >
                                
                                <div class="dark-bg txt-light box">
                                    
                                    <span class="txt-light" aria-hidden='true'></span>
                                    <span class="txt-light"  aria-hidden='true'></span>
                                    <span class="txt-light"  aria-hidden='true'></span>
                                </div>
                            
                            </div>
                            
                        </div>
                        <div id='navbar' className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
                            <div onClick={(event) => { event.target.blur(); setisActive(!isActive)}} className='navbar-start  '>                                
                                
                                {/* <div class=" mobile-nav-blk has-text-weight-bold ">
                                    <Link to="/"><a href="/" class=" mobile-nav-blk is-size-6  " > Home </a></Link>
                                </div> */}

                                <div class=" mobile-nav-blk has-text-weight-bold ">
                                    <Link to="/"><a href="/" class=" mobile-nav-blk is-size-6  " > Home </a></Link>
                                </div>

                                <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Registration"><a href="/Registration" class=" mobile-nav-blk is-size-6" > Registration </a></Link>
                                </div>

                                {/* <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Family-Timeline"><a href="/Family-Timeline" class=" mobile-nav-blk is-size-6" >Family Timeline</a></Link>
                                </div> */}
                                
                                <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Safety"><a href="/Safety" class=" mobile-nav-blk is-size-6" >Safety</a></Link>
                                </div>

                                <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Partner-Hotels"><a href="/Partner-Hotels" class=" mobile-nav-blk is-size-6" >Partner Hotels</a></Link>
                                </div>

                                <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Fallen-Officers"><a href="/Fallen-Officers" class=" mobile-nav-blk is-size-6" >Fallen Officers</a></Link>
                                </div>

                                <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Sponsors"><a href="/Sponsors" class=" mobile-nav-blk is-size-6" >Sponsors / Scholarships</a></Link>
                                </div>

                                <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Refunds"><a href="/Refunds" class=" mobile-nav-blk is-size-6" >Refund Policy</a></Link>
                                </div>

                                <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Photo-Gallery"><a href="/Photo-Gallery" class=" mobile-nav-blk is-size-6" >Photo Gallery</a></Link>
                                </div>

                                <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Contact-Us"><a href="/Contact-Us" class=" mobile-nav-blk is-size-6" >Contact / FAQ</a></Link>
                                </div>

                                <div class=" mobile-nav-blk has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                                    <Link to="/Login"><a href="/Login" class=" mobile-nav-blk is-size-6" >Login</a></Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </nav>
                
            </div>
{/* End of mobile / tablet view nav */}


{/* Start of desktop view nav */}
            <div class="is-hidden-touch">
                <nav class="" style={{backgroundColor: bgColors.Dark}} className={` ${isActive ? 'is-active' : ''}`} >
                    <div class=" center is-justify-content-space-around is-flex-wrap-nowrap m-4 pt-3"  >
                        <div class=" " onClick={(event) => { event.target.blur(); }}>
                        
                        <Link to="/"><a href="/" class=" " >  <img src="/assets/web-page-photos/Utah-Peace-Officers-3D-3-15.png" alt="Fallen officer" width={100}  ></img></a> </Link>
                        
                        </div>
                            
                        <div class=" txt-light has-text-weight-bold ">
                            <Link to="/"><a href="/" class=" txt-light is-size-6  " > Home </a></Link>
                        </div>

                        <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Registration"><a href="/Registration" class=" txt-light is-size-6" > Registration </a></Link>
                        </div>

                        {/* <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Family-Timeline"><a href="/Family-Timeline" class=" txt-light is-size-6" >Family Timeline</a></Link>
                        </div> */}
                        
                        <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Safety"><a href="/Safety" class=" txt-light is-size-6" >Safety</a></Link>
                        </div>

                        <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Partner-Hotels"><a href="/Partner-Hotels" class=" txt-light is-size-6" >Partner Hotels</a></Link>
                        </div>

                        <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Fallen-Officers"><a href="/Fallen-Officers" class=" txt-light is-size-6" >Fallen Officers</a></Link>
                        </div>

                        <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Sponsors"><a href="/Sponsors" class=" txt-light is-size-6" >Sponsors / Scholarships</a></Link>
                        </div>

                        <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Refunds"><a href="/Refunds" class=" txt-light is-size-6" >Refund Policy</a></Link>
                        </div>

                        <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Photo-Gallery"><a href="/Photo-Gallery" class=" txt-light is-size-6" >Photo Gallery</a></Link>
                        </div>

                        <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Contact-Us"><a href="/Contact-Us" class=" txt-light is-size-6" >Contact / FAQ</a></Link>
                        </div>

                        <div class=" txt-light has-text-weight-bold" onClick={(event) => { event.target.blur(); }}>
                            <Link to="/Login"><a href="/Login" class=" txt-light is-size-6" >Login</a></Link>
                        </div>


                        <div class="navbar-burger navbar-end" />                    
                        
                    </div>    
                </nav>
            </div>                    
            {/* End of desktop view nav */}
        </div>

        </>
    )
};

export default NavBar;