import React from 'react';
import {Link} from "react-router-dom";

export default function Registration() {
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Register</strong> </h1>
                <br></br>                    
            </section>
            <section class="light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">YOU MUST COMPLETE <a href='https://secured.utah.gov/utoutdoorrecdirectory/Forms/Page/utoutdoorrecdirectory/utohvcourse/0'>UTAH OFF HIGHWAY VEHICLE EDUCATION COURSE </a> PRIOR TO RIDING. </strong> </h1>
                <br></br>                    
            </section>
            <div class=" is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">
                <div>
                    <Link to="/Consent-Form">
                        <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/Consent-Form" >
                            <button class="light-bg center p-4 "><strong class="is-size-5">Trail Ride Waiver ( Digital )</strong></button>
                        </a>
                    </Link>
                   
                </div>
                <div>
                    <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/assets/docs/2022 Trail Ride Waiver.pdf" >
                        <button class="light-bg center p-4 "><strong class="is-size-5">Trail Ride Waiver ( PDF )</strong></button>
                    </a>
                    
                </div>  
            </div>

            <div class=" is-flex-desktop is-justify-content-space-around is-flex-direction-row m-4">
                <div class="half">
                    <div class="box is-size-5">
                        <h3 class="is-size-4 center"><strong>Motorcycle / ATV / UTV</strong></h3>
                        <br />
                        <p>Ride times start at 11:00 AM with 30 vehicles per time slot. Groups will continue every 30 minutes until 2:30 PM. You need to make sure to put a count of passengers per vehicle.</p>
                        <li>Motorcycle $20.00 each</li>
                        <li>ATV $30.00 each</li>
                        <li>UTV $40.00 each</li>
                        <li>Additional Passengers $5.00 each</li>
                        <br />
                        <div>
                            <div class="is-size-4 center">
                                <p>Select a time slot below</p>
                                <p><strong>Regristration is currently closed for this year. Check back soon for next year.</strong>  </p>
                            </div>
                                
                            <div class="is-size-4 center">
                                <p>Please complete one form per vehicle</p>
                            </div>
                                
                        
                            <div class=" is-flex-desktop is-flex-wrap-wrap is-justify-content-space-around is-flex-direction-row m-4">
                                <Link class="fourty center m-4"  to="/11AM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="/11AM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">11 AM</strong></button>
                                    </a>
                                </Link>
                                <Link class="fourty center m-4"  to="/1130AM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="/1130AM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">11:30 AM</strong></button>
                                    </a>
                                </Link>
                                <Link class="fourty center m-4"  to="/12PM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="/12PM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">12 PM</strong></button>
                                    </a>
                                </Link>
                                <Link class="fourty center m-4"  to="/1230PM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="/1230PM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">12:30 PM</strong></button>
                                    </a>
                                </Link>
                                <Link class="fourty center m-4"  to="/1PM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="/1PM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">1 PM</strong></button>
                                    </a>
                                </Link>
                                <Link class="fourty center m-4"  to="/130PM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="/130PM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">1:30 PM</strong></button>
                                    </a>
                                </Link>
                                <Link class="fourty center m-4"  to="/2PM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="/2PM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">2 PM</strong></button>
                                    </a>
                                </Link>
                                <Link class="fourty center m-4"  to="/230PM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="/230PM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">2:30 PM</strong></button>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div> 
                    <br />
                    


                    
                                            
                </div>


                <div class="half">
                    <div class="box is-size-5">
                        <h3 class="is-size-4 center"><strong>Jeeps & Related Vehicles</strong></h3>
                        <br />
                        <p><strong class="center">**Ride times may be adjusted if needed by the committee.**</strong></p>
                        <br />
                        <p>Beginning at 3:00 PM, there will be 15 vehicle slots for Jeeps and related vehicles. Groups continue every 30 minutes until 4:00 PM. You need to make sure to put a count of passengers per vehicle.</p>
                        <li>Jeep or Similar Vehicle $50.00 each</li>
                        <li>Additional Passengers $5.00 each</li>
                        <br />
                        <div>
                            <div class="is-size-4 center">
                                <p>Select a time slot below</p>
                                <p><strong>Regristration is currently closed for this year. Check back soon for next year.</strong>  </p>
                            </div>
                                
                            <div class="is-size-4 center">
                                <p>Please complete one form per vehicle</p>
                            </div>
                                
                        
                            <div class=" is-flex-desktop is-flex-wrap-wrap is-justify-content-space-around is-flex-direction-row m-4">
                                <Link class="fourty center m-4"  to="3PM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="3PM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">3 PM</strong></button>
                                    </a>
                                </Link>
                                <Link class="fourty center m-4"  to="/330PM">
                                    <a class="" target="_blank" rel="noopener noreferrer" href="/330PM" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">3:30 PM</strong></button>
                                    </a>
                                </Link>
                                
                            </div>
                        </div>
                    </div>


                    <br />
                    
                    
                </div>
            </div>
            <hr />
            <div class="m-4 is-size-5 box">
                <h3 class="is-size-4 center"><strong>Online Registration</strong></h3>
                <p>When registering for this year’s Fallen Peace Officers Trail Ride, The time you choose are only a preference, and you are not guaranteed that time slot. Please make sure to give us a correct email that you check often for further registration information. We need a valid email because you will be notified approximately one week prior to the Fallen Peace Officers Trail Ride of your assigned ride time. After submitting your information, you will be sent to pay your registration fees. You should also receive a registration confirmation email if your payment was successful. If, for whatever reason, you do not make payment at the same time as filling out the registration form, you must start over and fill out the registration form again. </p>
                <br />
                <br />
                
                
                
                <br />
                <br />
            </div>

            
        </>
    )
}