import React from 'react';

export default function Hotels() {
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Hotels</strong> </h1>
                <br></br>                    
            </section>

            <div class="m-4 is-size-5 box">
                <h3 class="is-size-3 center"><strong>Host Hotel</strong></h3>
                    <p class="center"><strong>TBD</strong> </p>
                    {/* <p class="center">890 North Main Street Moab, Utah 84532</p>
                    <p class="center">Reservations:  <a href="tel:4352599190"> 435-259-9190</a> </p>
                    <a class="center m-3" target="_blank" rel="noopener noreferrer" href="https://www.hyatt.com/en-US/hotel/utah/hyatt-place-moab/slczm/rooms" >
                        <button class="light-bg center p-4 "><strong class="is-size-5">Hyatt Place Moab </strong></button>
                    </a> */}
                <br />
                
                
            </div>
            <hr />
            <h3 class="is-size-3 center"><strong>Other Hotels</strong></h3>
            <div class=" is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">
                <div class="half">
                    <div class="box is-size-5">
                        <p class="center"><strong>Hyatt Place Moab</strong> </p>
                        <p class="center">890 North Main Street Moab, Utah 84532</p>
                        <p class="center">Reservations:  <a href="tel:4352599190"> 435-259-9190</a> </p>
                        <a class="center m-3" target="_blank" rel="noopener noreferrer" href="https://www.hyatt.com/en-US/hotel/utah/hyatt-place-moab/slczm/rooms" >
                            <button class="light-bg center p-4 "><strong class="is-size-5">Hyatt Place Moab </strong></button>
                        </a>
                        <br />
                    </div>
                    <div class="box is-size-5">
                        <p class="center"><strong>Bowen Motel</strong> </p>
                        <p class="center">169 N Main St, Moab, UT 84532</p>
                        <p class="center">Reservations: 800-874-5439 or 435-259-7132</p>
                        <a class="center m-3" target="_blank" rel="noopener noreferrer" href="https://www.bowenmotel.com/" >
                            <button class="light-bg center p-4 "><strong class="is-size-5">Bowen Motel</strong></button>
                        </a>
                        <br />
                    </div>
                    <br />
                                            
                </div>


                <div class="half">
                    <div class="box is-size-5">
                        <p class="center"><strong>Aarchway Inn</strong> </p>
                        <p class="center">1551 North Highway 191 Moab, UT 84532</p>
                        <p class="center">Reservations: 800-341-9359 or (435) 259-2599</p>
                        <a class="center m-3" target="_blank" rel="noopener noreferrer" href="https://aarchwayinn.com/" >
                            <button class="light-bg center p-4 "><strong class="is-size-5">Aarchway Inn</strong></button>
                        </a>
                        <br />
                    </div>
                    <br />

                    <div class="box is-size-5">
                        <p class="center"><strong>Holiday Inn Express Hotel & Suites Moab</strong> </p>
                        <p class="center">1515 N Highway 191 Moab, UT 84532</p>
                        <p class="center">Reservations: 800-465-4329 or 435-259-1150</p>
                        
                        <a class="center m-3" target="_blank" rel="noopener noreferrer" href="https://www.ihg.com/holidayinnexpress/hotels/us/en/reservation" >
                            <button class="light-bg center p-4 "><strong class="is-size-5">Holiday Inn Express Hotel & Suites Moab</strong></button>
                        </a>
                        <br />
                    </div>
                    <br />
                    
                    
                </div>
            </div>
            
        </>
    )
}