import React from 'react';
import { RideDate } from './common/shared';

export default function Contact() {
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Contact us / FAQ</strong> </h1>
                <br></br>                    
            </section>
            <hr />
            <div class= "">                                                        
                <iframe title="contact us " width="100%" height= "900px" src= "https://fpot3.aidaform.com/contact-us" frameborder= "0" marginwidth= "0" marginheight= "0" > </iframe>
            </div>

            <hr />
            <div>
            
            <h3 class="is-size-3 center"><strong>FAQ</strong></h3>
            <div class=" is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">
                <div class="half">
                    <div class="box is-size-5">
                        <p class="center"><strong>What date is the Fallen Peace Officer Trail Ride?</strong> </p>
                        <br />
                        <p>The date of the Fallen Peace Officer Trail ride is Saturday <RideDate></RideDate>.</p>
                        <br />
                    </div>
                    <div class="box is-size-5">
                        <p class="center"><strong>Where can I get detailed information about Utah OHV laws and rules?</strong> </p>
                        <br />
                        <p>Go to the Utah State Parks website at <a target="_blank" rel="noopener noreferrer" href="http://stateparks.utah.gov/ohv">http://stateparks.utah.gov/ohv</a> </p>
                        <br />
                    </div>
                    <br />
                    <div class="box is-size-5">
                        <p class="center"><strong>Can I get a refund on my registration fees?</strong> </p>
                        <br />
                        <p>We understand that things happen unexpectedly after you’ve made plans to attend the trail ride and that you may desire a refund. Regretfully, we have determined no registration refunds will be allowed. This decision is based upon the <a href="https://www.irs.gov/charities-non-profits/charitable-organizations/exemption-requirements-501c3-organizations">501-c3 status</a>  of the UPOA Charitable Foundation and the Fallen Peace Officer Trail fund and the parameters of how registration fees are utilized. All donations and registration fees are strictly used to support the Fallen Peace Officer Trail Ride through advertising, marketing, printing, the purchase of miscellaneous support materials, and scholarship funding. As a supporter, please understand this policy and willingly accept these registration fees are crucial to the cause of furthering awareness of our fallen law enforcement heroes and supporting educational scholarships to deserving individuals. We thank you for your support and understanding.</p>
                        <br />
                    </div>
                    <br />

                    <div class="box is-size-5">
                        <p class="center"><strong>Where do I go to get my credentials for the Fallen Peace Officer Trail Ride?</strong> </p>
                        <br />

                        <p>All riders and passengers will be required to pre-register online through thefallenride website. Friday night packets may be picked up at the location noted on the registration page. Friday night packet pickup will be from 5:00 PM – 8:00 PM. The day of package pickup will be at the Old Spanish Trail Arena on Saturday, November 12th starting at 10:00 AM. Please pick up your packet at least 30 minutes before ride time. All registered operators must check in at the trailhead before their ride time.</p>

                        <br />
                    </div>
                    <br />

                    <div class="box is-size-5">
                        <p class="center"><strong>What camping is available in the immediate area?</strong> </p>
                        <br />
                        <p>There is no designated supported camping near the trail. However, there are many free dry camping spots in the area near the FPOT trail. The following are references for camping in the area:</p>
                        <p><strong>BLM’s website</strong>: <a target="_blank" rel="noopener noreferrer" href="https://www.blm.gov/visit/moab-field-office-blm"> http://www.blm.gov</a> </p>
                        <p><strong>BLM Camping near the FPOT Trial Camping near FPOT is limited to designated sites</strong>: <a target="_blank" rel="noopener noreferrer" href="https://www.blm.gov/visit/moab-field-office-blm">http://www.blm.gov</a></p>
                        <p><strong>Grand County’s tourism website</strong>: <a target="_blank" rel="noopener noreferrer" href="https://www.discovermoab.com/campgrounds/">http://discovermoab.com/campgrounds</a></p>
                        <br />
                        <p>Camping on state land (which surrounds FPOT and Sovereign Trail) is limited to existing campsites. Specifically, please camp and keep all vehicles on barren ground within the existing sites. Portable toilets are required. You may use a chemical treatment such as Wag Bags, which are available at outdoor stores. These sanitary products allow you pack out human waste, and then dispose of it in a garbage can legally.</p>
                        <br />
                        <p>No camping will be available at the FPOT trailhead or at Dalton Well.</p>
                        <br />
                        {/* <p>There is camping further up Dalton Well Road (one mile up, on the left side), but the wash crossing is subject to flooding (cross at your own risk).</p> */}
                        
                    </div>
                    <br />
                                            
                </div>


                <div class="half">
                    <div class="box is-size-5">
                        <p class="center"><strong>Can I bring my Utility Terrain Vehicle (UTV) on the Fallen Peace Officer Trail Ride?</strong> </p>
                        <br />
                        <p>Yes, motorcycles, ATVs and UTVs are all welcome. Full-size vehicles (aka 4WD) may go after 3:00.</p>
                        <br />
                    </div>
                    <br />

                    <div class="box is-size-5">
                        <p class="center"><strong>Do I have to wear a helmet?</strong> </p>
                        <br />
                        <p><strong>YES</strong> , if under 18 yrs of age. Nearly every year, lives are saved because individuals wear helmets. We urge all riders to use helmets properly while on the trail ride. Utah State Law, however, does not require helmets for individuals 18 and over. In other words, please wear a helmet.</p>
                        <br />
                    </div>
                    <br />
                    
                    <div class="box is-size-5">
                        <p class="center"><strong>What is the Spring weather like in Moab?</strong> </p>
                        <br />
                        <p>It is important to be prepared for any and all weather conditions. Moab Spring weather can change from trail to trail and potentially several times throughout the day. The Moab area tends to be dry and sunny, but Spring weather can produce rains and even snows. Layered clothing in case of changing weather conditions is recommended, as well as, plenty of drinking water and sun protection.</p>
                        <br />
                    </div>
                    <br />

                    <div class="box is-size-5">
                        <p class="center"><strong>Can I ride double on the Fallen Peace Officer Trail?</strong> </p>
                        <br />
                        <p>Utah State Law does not prohibit riding double. We have found that some riders and passengers are not physically prepared for the trail challenges and/or length of the ride while riding double. Please be sure to have the necessary endurance, riding skills, and equipment that will make riding double both safe and enjoyable.</p>
                        <br />
                    </div>
                    <br />

                    <div class="box is-size-5">
                        <p class="center"><strong>What if I want to participate in the memorial program but do not wish to ride the trail?</strong> </p>
                        <br />
                        <p>The memorial trailhead site (North of Moab) will have limited vehicle parking available for you to walk to the trailhead marker. The commemorative program will be located at the Old Spanish Trail Arena (South of Moab) with the solemnity walk. Free public parking.</p>
                        <br />
                    </div>
                    <br />

                    <div class="box is-size-5">
                        <p class="center"><strong>What about operators under the age of 16 that wish to participate in the Fallen Peace Officer Trail Ride?</strong> </p>
                        <br />
                        <p>No one under eight years of age is allowed to operate any OHV on public lands, roads, or trails in Utah. Operators 8 – 15 years of age may operate an OHV provided they possess an Education Certificate issued by Utah State Parks or equivalent from their home state. Riders under the age of 16 must be supervised at all times by a person of at least 18 years of age at an oversight distance of no more than 300 feet and within which visual contact is maintained and advice and assistance can be given and received. Operators 16 years of age and older require a valid driver license.</p>
                        <br />
                    </div>
                    <br />

                    <div class="box is-size-5">
                        <p class="center"><strong>What about non-resident OHV registrations?</strong> </p>
                        <br />
                        <p>Non-resident OHV operators are required to purchase a Non-resident OHV Permit for each OHV they bring into Utah. These permits are available from vendors located near popular OHV destinations. A complete list of vendors is available at <a target="_blank" rel="noopener noreferrer" href="https://stateparks.utah.gov/activities/off-highway-vehicles/ohv-permits-faqs/">https://stateparks.utah.gov</a>.</p>
                        <br />
                    </div>
                    <br />
                    
                </div>
            </div>
            </div>
        </>
    )
}