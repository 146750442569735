import React from 'react';


export default function PhotoSubmission() {
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Photo Submission</strong> </h1>
                <br></br>                    
            </section>
            <div class= "">                                                        
                <iframe title="contact us " width="100%" height= "1500px" src= "https://fpot3.aidaform.com/photo-submission" frameborder= "0" marginwidth= "0" marginheight= "0" > </iframe>
            </div>
            
        </>
    )
}