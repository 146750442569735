import React from 'react';

export default function Refunds() {
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Refunds</strong> </h1>
                <br></br>                    
            </section>
            <div class="m-4 is-size-5 box">
                <p>We understand that things happen unexpectedly after you’ve made plans to attend the trail ride and that you may desire a refund. Regretfully, we have determined no registration refunds will be allowed. This decision is based upon the <a href="https://www.irs.gov/charities-non-profits/charitable-organizations/exemption-requirements-501c3-organizations">501-c3 status</a>  of the UPOA Charitable Foundation and the Fallen Peace Officer Trail fund and the parameters of how registration fees are utilized. All donations and registration fees are strictly used to support the Fallen Peace Officer Trail Ride through advertising, marketing, printing, the purchase of miscellaneous support materials, and scholarship funding. As a supporter, please understand this policy and willingly accept these registration fees are crucial to the cause of furthering awareness of our fallen law enforcement heroes and supporting educational scholarships to deserving individuals. We thank you for your support and understanding.</p>
            </div>
            
        </>
    )
}