import React, {useState} from "react";
import { useHistory } from "react-router-dom";



export default function Login() {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (user === 'Fallenride' && password === '2024' ){
            history.push('/Family-Timeline');
            console.log('logged in');
        } else {
            console.log('incorrect');
            setError('Invalid Username or Password')
        }
    }

    
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Login</strong> </h1>
                <br></br>                    
            </section>
            <div class=" is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">   
                <form onSubmit={handleSubmit}>
                    {error && <div>{error}</div>}
                    <label class="is-size-5"><strong>Username</strong>
                    
                        
                        <br />
                        <input class="input is-medium" type="text" value={user} onChange={(e) => setUser(e.target.value)} />
                    </label>
                    <br />
                    <br />
                    <label class="is-size-5 "><strong class="has-text-centered">Password</strong>
                        
                        <br />
                        <input class="input is-medium" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label>
                    <br />
                    <br />
                    <div class="center">
                        <button class="button is-medium is-primary is-outlined"  type="submit">Log in</button>
                    </div>
                    
                </form>
                
            </div>

        </>
    )
}