import React from 'react';

export default function Safety() {
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Trail Ride Safety</strong> </h1>
                <br></br>                    
            </section>
            <div class="is-size-4 m-4">
                <p >Please note: Riders need to accurately estimate their abilities and ensure their skills are suitable to the Fallen Peace Officer Trail which has a rating of moderately difficult. Moderately difficult trails are generally known for being steep, rough, and challenging.</p>
                <img class="ninetyNine" src="/assets/web-page-photos/Ride with Respect PSA.jpg" alt="ride with respect banner"  />
                <strong>
                    <li>When riding/driving, follow the trail precisely. Don't just stay on designated roads and trails in general. Keep your tires from touching vegetation or even the biological soil crust.</li>
                    
                    <li>Reduce speed/sound/dust when passing anyone, including mountain bikers / campers / even cows. </li>
                    
                    <li>Drink at least a gallon of water per day, which is even true in November due to the dry air.</li>
                </strong>
                <br />

            </div>
            
            <div class=" is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4 is-size-5">
                    <div class="half">
                        <div class="box">
                            <div class="center" >
                                <img class="ninetyNine" src="/assets/web-page-photos/DSC06758.jpg" alt="Fallen Officer"  />
                            </div>
                            <br />
                            <p>We strongly suggest that everyone wear a helmet and protective gear. Utah State Law requires that all operators and passengers under the age of 18 <strong>MUST WEAR A HELMET!</strong> </p>
                            <br />
                            <p>Eye protection is recommended. Tree branches, flying dirt, dust, rocks, and insects all pose the potential for getting into unprotected eyes. Protective clothing is recommended for sudden spills that can be caused by ruts, rocks, or roots that can throw an operator/passenger off course and into a spill. This is especially true if a rider is travelling too fast for trail conditions or personal ability.</p>
                            <br />
                            <p>Don’t push yourself beyond your limits either in the speed or distance you plan to travel. Exceeding either limit leads to fatigue and loss of control which can cause accidents. The Fallen Peace Officer Trail is meant for leisurely travel over moderately challenging terrain. The trail is not meant for speed. Most OHV accidents can be attributed to excessive speed and travelling too fast for conditions. Accidents may also be caused by excessive dust and limited visibility.</p>
                        </div> 
                        <br />
                        <div class="box">
                            <div class="center" >
                                <img class="ninetyNine" src="/assets/web-page-photos/DSC07260.jpg" alt="Fallen Officer"  />
                            </div>
                            <br />
                            <p>Operators can minimize dust and increase riding safety and enjoyment by allowing approximately 15 – 30 seconds between yourself and the operator ahead of you. Do not be afraid of getting lost or being left by your riding group. The trail is clearly marked and uniformed, trail marshals will be riding throughout the trail system.</p>
                            <br />
                            <p>Riders and passengers should plan to carry plenty of water or other non-alcoholic beverages. Unless you are used to the aridity of southern Utah, you can lose more body fluids than you realize and risk dehydration and exhaustion.</p>
                            <br />
                            <p>Do not drink alcohol or use drugs while riding. As with automobiles, operating an OHV while under the influence of alcohol and/or drugs can lead to serious accidents. Utah State Law prohibits the use of alcohol and/or drugs while operating an OHV or any other motor vehicle.</p>
                        </div> 
                        <br />
                                             
                    </div>


                    <div class="half">
                        <div class="box">
                            <div class="center" >
                                <img class="ninetyNine" src="/assets/web-page-photos/DSC01301.jpg" alt="Fallen Officer"  />
                            </div>
                            <br />
                            <p>OHV operators shall yield right-of-way to traditional motor vehicles at all times. OHV operators shall also yield to horseback riders who may be using the same trail system. When meeting horseback riders, OHV operators should pull to the side of the trail, turn off their engines, and allow the horse to pass without being spooked by your machine. The Fallen Peace Officer Trail will be restricted to one-way travel the day of the dedication event. However, connecting trails and roads are subject to two-way traffic. Please use extreme caution when operating to avoid head –on collisions. Be courteous when allowing other riders to pass.</p>
                            <br />
                        </div>
                        <br />
                        <div class="box">
                            <div class="center" >
                                <img class="ninetyNine" src="/assets/web-page-photos/DSC05473.jpg" alt="Fallen Officer"  />
                            </div>
                            <br />
                            <p>Please stay on designated roads and trails and prevent the misuse of public lands. Riding on public lands is a privilege. Please help us to preserve this privilege and TREAD LIGHTLY.</p>
                            <br />
                            <p>Proper pre-planning is the key to a successful trip. Once you embark on the trail, you are in a different world with few support services. It is important that you have everything you might need. We suggest at a minimum that each OHV be prepared for any emergency and carry a tool kit specific to your machine, extra spark plugs, a tire repair kit, tow strap, a first aid kit, duct tape and electrical tape, a flashlight, matches/lighter, and plenty of extra food and water. Operators should know the cruising range of their OHV and plan accordingly for fuel consumption. There are no fuel stations within the Fallen Peace Officer Trail system.</p>
                            <br />
                           
                        </div>
                         
                    </div>
                    
                </div>
            <div class="m-4 is-size-5 box">
                
                
                
                
                

            </div>
        </>
    )
}