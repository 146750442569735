// import logo from './logo.svg';
import './App.css';
import "bulma/css/bulma.css";
import React, { useEffect } from 'react';
import {Route, useLocation } from 'react-router-dom';


import Home from './pages/home';
import NavBar from './pages/nav';
import Contact from './pages/contact';
import FallenOfficers from './pages/fallen-officers';
import FamilyTimeline from './pages/family-timeline';
import Hotels from './pages/hotels';
import PhotoGallery from './pages/photo-gallery';
import Refunds from './pages/refunds';
import Registration from './pages/registration';
import Safety from './pages/safety';
import Sponsors from './pages/sponsors';
import PhotoSubmission from './pages/photo-submission';
import OfficerNomination from './pages/nominate';
import ScholarshipApp from './pages/scholarship-app';
import ConsentForm from './pages/consent-form';
import ElevenAm from './pages/11AM';
import ElevenThirty from './pages/1130AM';
import Twelve from './pages/12PM';
import TwelveThirty from './pages/1230PM';
import OnePm from './pages/1PM';
import OneThirtyPm from './pages/130PM';
import TwoPM from './pages/2PM';
import TwoThirtyPM from './pages/230PM';
import ThreePM from './pages/3PM';
import ThreeThirtyPM from './pages/330PM';
import Donation from './pages/donation';
import Login from './pages/login';
// import Footer from './pages/footer';


const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};




function App() {
  return (
    <ScrollToTop>
      <div>
          <NavBar></NavBar>

          <Route exact path ="/" component={Home} />
          <Route exact path ="/Home" component={Home} />
          <Route exact path ="/11AM" component={ElevenAm} />
          <Route exact path ="/1130AM" component={ElevenThirty} />
          <Route exact path ="/12PM" component={Twelve} />
          <Route exact path ="/1230PM" component={TwelveThirty} />
          <Route exact path ="/1PM" component={OnePm} />
          <Route exact path ="/130PM" component={OneThirtyPm} />
          <Route exact path ="/2PM" component={TwoPM} />
          <Route exact path ="/230PM" component={TwoThirtyPM} />
          <Route exact path ="/3PM" component={ThreePM} />
          <Route exact path ="/330PM" component={ThreeThirtyPM} />
          <Route exact path ="/Contact-Us" component={Contact} />
          <Route exact path ="/Consent-Form" component={ConsentForm} />
          <Route exact path ="/Donations" component={Donation} />
          <Route exact path ="/Fallen-Officers" component={FallenOfficers} />
          <Route exact path ="/Login" component={Login} />
          <Route exact path ="/Nominate-Officer" component={OfficerNomination} />
          <Route exact path ="/Family-Timeline" component={FamilyTimeline} />
          <Route exact path ="/Partner-Hotels" component={Hotels} />
          <Route exact path ="/Photo-Gallery" component={PhotoGallery} />
          <Route exact path ="/Photo-Submission" component={PhotoSubmission} />
          <Route exact path ="/Refunds" component={Refunds} />
          <Route exact path ="/Registration" component={Registration} />
          <Route exact path ="/Safety" component={Safety} />
          <Route exact path ="/Sponsors" component={Sponsors} />

          <Route exact path ="/Apply-for-scholarship" component={ScholarshipApp} />


          <NavBar></NavBar>
          {/* <Footer></Footer> */}                  
      </div>
    </ScrollToTop>

  );
}

export default App;
