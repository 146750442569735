import React from 'react';
// import {Link} from "react-router-dom";


export default function Sponsors() {
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Sponsors</strong> </h1>
                <br></br>  
                                 
            </section>
            <div class=" is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">
                <div>
                    <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/assets/docs/2022 Sponsor Form.pdf" >
                        <button class="light-bg center p-4 "><strong class="is-size-5">Sponsor Form ( PDF )</strong></button>
                    </a>                   
                </div>
                <div>                   
                    <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/assets/docs/2022 Sponsor Ride Info.pdf" >
                        <button class="light-bg center p-4 "><strong class="is-size-5">Sponsor Ride Info ( PDF )</strong></button>
                    </a>                    
                </div>
                {/* <div>
                    <Link to="/Consent-Form">
                        <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/Consent-Form" >
                            <button class="light-bg center p-4 "><strong class="is-size-5">Trail Ride Waiver ( Digital )</strong></button>
                        </a>
                    </Link>
                    
                    <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/assets/docs/2022 Trail Ride Waiver.pdf" >
                        <button class="light-bg center p-4 "><strong class="is-size-5">Trail Ride Waiver ( PDF )</strong></button>
                    </a>
                   
                </div> */}
                 
                  
            </div>
            
            <div class="m-4 is-size-5 box">
                <h3 class="is-size-3 center"><strong>Full Sponsorship (Platinum) $2500.00 and above</strong></h3>
                <div class="has-text-centered">
                    <li>4 ride passes to the Ride</li>
                    
                    <li>Recognition in the event program</li>
                    <li>Full page add in the Utah Peace Officer magazine for one year*</li>
                    <li>Full sized plaque for your office showing the officers’ name and milepost you sponsored</li>
                </div>
                
                <br />
                
                
            </div>
            <hr />
            <div class=" is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">
                <div class="half">
                    <div class="box is-size-5">
                        <p class="center"><strong>Half sponsorship (Gold) $1250.00 – $2499.00</strong> </p>
                        <br />
                        <li>2 ride passes to the Ride</li>
                        <li>Recognition in the event program</li>
                        <li>Half page add in the Utah Peace Officer magazine for one year*</li>
                        <li>Half sized plaque for your office showing the officers’ name and milepost you sponsored</li>
                        <br />
                    </div>
                   
                    <br />
                                            
                </div>


                <div class="half">
                    <div class="box is-size-5">
                        <p class="center"><strong>General Sponsorship (Bronze) Less than $1250.00</strong> </p>
                        <br />
                        <li>1 ride passes to the Ride</li>
                        <li>Recognition in the event program</li>
                        <li>Business card size add in the Utah Peace Officer magazine for one year*</li>
                        <br />
                    </div>
                    <br />

                </div>
            </div>
            <hr />
            <div class="m-4 is-size-5">
                <p><strong>*Note: The Utah Peace Officer magazine is sent out to every Sheriff, Police Chief and legislator in the state of Utah.</strong></p>
                <br />
                <p>If you are interested in being part of this great event please contact the UPOA Office:</p>
                <br />
                <p>5671 South Redwood Rd. #19</p>
                <p>Taylorsville, UT 84123</p>
                <p>Phone: <a href="tel:8013130760">(801) 313-0760</a> </p>
            </div>
            
            
        </>
    )
}