import React from 'react';
import { Link } from 'react-router-dom';
import { RideDate } from './common/shared';


export default function Home() {
    return(
        <>
            <h1 class="title center m-4"><strong> 9th Annual Ride — Saturday <RideDate></RideDate> 9:00 A.M. — Moab, Utah </strong> </h1>
            <div class="center" >
                <img class="ninetyNine" src="/assets/web-page-photos/Romrell-2090-David1.jpg" alt="Fallen Officer"  />
            </div>
            <br />


            <div class="m-4 is-size-5">

                <p>On April 23, 2013, the Utah Peace Officers Association unveiled “The Fallen Peace Officer Trail” just north of Moab, Utah. We invite everyone to bring their off-highway vehicle to the event. Fallen Officers’ names will be posted along this trail.</p>
                <br />
                <p class="is-size-5 "><a target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/UPOAFPOT'>Follow us on Facebook</a>   </p>
                <br />
                
                
                <div class=" is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">
                    <div class="half">
                        <div class="box">
                            <li>The fallen officer trail ride is open to the public, so bring the entire family.</li>
                            <li>Proceeds fund scholarships for the families of the fallen officers and those injured in the line of duty.</li>
                            <li>Book early to guarantee ride time</li>
                            <li> Book early to guarantee your room or RV accommodations</li>
                        </div> 
                        <br />
                        <div class="box is-size-5">
                            <h3 class="is-size-4 center"><strong>Important Times & Locations</strong></h3>
                            <br />
                            <p> <strong>Friday Night Registration & Packet Pick-up</strong></p>
                            <p>Aarchway Inn</p>
                            <p>1551 North riverview drive</p>
                            
                            <br />
                            <p> <strong>Ride Program / Ceremony site</strong></p>
                            <p>Old Spanish Trail Arena – 3641 S Hwy 191 (south of Moab)</p>
                            <br />
                            <p> <strong>Ceremony</strong></p>
                            <p>Ceremony begins at 9:00 AM. </p>
                            <br />
                            <p> <strong>Ride</strong></p>
                            <p>Rides begin at 11:00 AM Fallen Peace Officer Trail Head Parking at the trailhead.</p>
                            <br />
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10381.130706383794!2d-109.49476314184058!3d38.5186533110392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8747e372f6df4f0f%3A0xf2eb8ce5e32d0eb3!2sOld%20Spanish%20Trail%20Arena!5e1!3m2!1sen!2sus!4v1647453984804!5m2!1sen!2sus" width="99%" height="450" alt="map of location" allowfullscreen="true" loading="lazy" title="map of location"></iframe>
                        </div>                      
                    </div>


                    <div class="half">
                        <div class="box">
                            <h3 class="is-size-4 center"><strong>Why are we riding?</strong></h3>
                            <br />
                            <p> To honor the fallen and to help in a very small way, their families further a part of their lives in the form of a scholarship.</p>
                        </div>
                        <br />
                        <div class="box">
                            <h3 class="is-size-4 center"><strong>Forms</strong></h3>
                            <br />
                            <div class=" is-flex-desktop is-flex-direction-row is-flex-wrap-wrap m-4 is-justify-content-space-evenly">

                                    <a class=" " target="_blank" rel="noopener noreferrer" href="/assets/docs/UPOA BRODY YOUNG SCHOLARSHIP 05-22.docx.pdf" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">Scholarship Application (PDF)</strong></button>
                                    </a>
                                    <Link to='/Apply-for-scholarship'>
                                        <a class=" " target="_blank" rel="noopener noreferrer" href="/Apply-for-scholarship" >
                                            <button class="light-bg center p-4 "><strong class="is-size-5">Scholarship Application (Online)</strong></button>
                                        </a>
                                    </Link>
                                    

                                <br />
                            </div>
                            <div class=" is-flex-desktop is-flex-direction-row is-flex-wrap-wrap m-4 is-justify-content-space-evenly">
                                <Link to="/Nominate-Officer">
                                    <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/Nominate-Officer" >
                                        <button class="light-bg center p-4 "><strong class="is-size-5">Nominate a fallen officer</strong></button>
                                    </a>
                                </Link>
                                <br />
                                <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/" >
                                    <button class="light-bg center p-4 "><strong class="is-size-5"> Potential Sponsor Letter</strong></button>
                                </a>
                                <br />
                                <a class="center m-1" target="_blank" rel="noopener noreferrer" href="/Donations" >
                                    <button class="light-bg center p-4 "><strong class="is-size-5"> Donations</strong></button>
                                    {/* add a link to payment processor */}
                                </a>
                            </div>
                        </div>


                         
                    </div>
                    
                </div>
                
            </div>
            
            {/* <div class="m-4">
                <a class="center " target="_blank" rel="noopener noreferrer" href="/assets/docs/The Fallen Officer trail Ride.pdf" >
                    <button class="light-bg center p-4 "><strong class="is-size-5">PDF Map of Trail</strong></button>
                </a>
                <p class="center is-size-4"><strong>To see GPS location on PDF map you will  need to use a Geo pdf viewer like <a target="_blank" rel="noopener noreferrer" href="https://www.avenzamaps.com/">Avenza Maps.</a> See their site for more details.</strong> </p>
                <iframe class="has-ratio" width="100%" height="700" src="https://www.youtube.com/embed/videoseries?list=PL9TJbUZrn3crhWy8hhTeYHTdwG1MPIfAC&autoplay=1&mute=1&start=2&loop=1" frameborder="0" allowfullscreen title="Youtube I frame"></iframe>
            </div> */}

        </>
    )
}