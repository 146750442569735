import React from 'react';

function RideDate(){
    return(
        <>
            April 15th
        </>
    )
}

function Year(){
    return(
        <>
            2024
        </>
    )
}

// Password for login is the current year but has to be manually updated in the Login.jsx page.


export {
    RideDate,
    Year,

}



// Notes from call with Del on 1/6/23
// Create user name and pass with login info and push family ride info into that page. 
// add a Follow us on FB with FB icon linking to UPOA FB page. 
// Get updated Sponsor forms from Del. 

// Move waiver buttons from Sponsors to the regristration page. ** check **
// ON REGISTER PAGE ADD BANNER that people need to complete web thing before they are allowed to ride. Link in email from del named OHV link ** check **
// create a link for donation button on home page to go to a donations payment processing with STRIPE.com ** check **
// Reset count for all regristration forms ** check **
// Add a disclaimer box to be checked stating " I have completed or will complete the OHV training prior to my ride time. " ** check **
// Donation page with aida form that links to stripe with a Thank you for your donation and an amount that they are able to donate. suggested amounts 20,50,100 ** check **
// Bring back buttons for regristration forms.  ** check **



