import React from 'react';

export default function FamilyTimeline() {
    return(
        <>
            <section class="hero  light-bg block m-4 pt-5" > 
                <h1 class="has-text-centered is-size-1 m-1"> <strong class="has-text-black">Family Ride Information</strong> </h1>
                <br></br>                    
            </section>

            {/* move family ride information to a password protected area / tab across the top make User name Familyride Password = current year */}
            <div class=" is-flex-desktop  is-justify-content-space-around is-flex-direction-row m-4">
                    <div class="half">
                      
                        <div class="box is-size-5">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37009.910875715665!2d-109.73794097671068!3d38.737316792679266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8748713c24c21bb1%3A0x19940cb8373fdd57!2sFallen%20Officer%20Peace%20Trail%20(Trailhead)!5e1!3m2!1sen!2sus!4v1648137306103!5m2!1sen!2sus" width="99%" height="450" alt="map of location" allowfullscreen="true" loading="lazy" title="map of location"></iframe>
                        </div>                      
                    </div>
                    <br />

                    <div class="half">
                       {/* move family ride information to a password protected area / tab across the top make User name Familyride Password = current year */}
                       <div class="box is-size-5">
                            
                            <p> <strong>Ride</strong></p>
                            <p>Hwy 191 MP 139.5 (Fallen Peace Officer Trail Head)</p>
                            
                            <br />
                            <p> <strong>Family/Agency Dinner</strong></p>
                            <p>Grand Center – 182 N 500 W</p>
                            <br />
                            <p> <strong>Ride</strong></p>
                            <p>Rides begin at 1:00 PM Fallen Peace Officer Trail Head Parking at the trail head.</p>
                            <br />
                            
                        </div> 
 


                         
                    </div>
                    
                </div>
        </>
    )
}